
import { PropType } from 'vue'
import { MenuList } from './types'
export default {
  props: {
    menu: {
      type: Object as PropType<MenuList>
    }
  }
}
